<template>
  <div>
    <div class="row">
      <div class="flex xs12">
        <va-collapse withBackground>
          <span slot="header">{{ $t('layout.filters.label') }}</span>
          <template slot="body">
            <filters
              :loading="loading"
              :actions="filterActions"
              @filter="filterData"
              @clear-filters="filterData(null)"
            />
          </template>
        </va-collapse>
      </div>
    </div>
    <div class="row">
      <div class="flex xs12">
        <va-card :title="$t('reports.investments.index')">
          <remote-table
            class="table-hover table-striped"
            :columns="tableFields"
            :data="tableData"
            :loading="loading"
            :search="searchOptions"
            :pagination="pagination"
            :params="serverParams"
            :queries="serverParams.queries"
            :sortField="sort.field"
            :sortType="sort.type"
            :top-buttons="['xlsx']"
            :action-options="['view', 'delete']"
            controller="proyectionInvestments"
            crud-links="investments"
            @update-data="updateData"
            @search-data="searchByName"
            @delete-item="tryDelete"
          />
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Filters = () => import(/* webpackPrefetch: true */ '@/components/extras/Filters')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'investments-index',
  components: {
    Filters, RemoteTable,
  },
  data () {
    return {
      loadingError: false,
      pagination: {},
      tableData: [],
      searchQuery: '',
      loading: false,
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
        // externalQuery: searchQuery
      },
      sort: {
        field: 'Teams.code',
        type: 'desc',
      },
      serverParams: {
        queries: '',
      },
      filterActions: ['regions', 'countries', 'districts', 'team', 'yearSince', 'yearUntil'],
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    tableFields () {
      return [
        {
          name: 'proyection.team.code',
          title: this.$t('tables.headings.team'),
          sortField: 'Teams.code',
        },
        {
          name: 'value',
          title: this.$t('tables.headings.value'),
          callback: this.totalFormat,
          sortField: 'value',
        },
        {
          name: 'region_confirmation',
          title: this.$t('tables.headings.region_confirmed'),
          callback: this.confirmLabel,
          visible: this.currentUser.can('Regions', 'index'),
          sortField: 'region_confirmation',
        },
        {
          name: 'country_confirmation',
          title: this.$t('tables.headings.country_confirmed'),
          callback: this.confirmLabel,
          visible: this.currentUser.can('Countries', 'index'),
          sortField: 'country_confirmation',
        },
        {
          name: 'team_confirmation',
          title: this.$t('tables.headings.team_confirmed'),
          callback: this.confirmLabel,
          visible: this.currentUser.can('Teams', 'index'),
          sortField: 'team_confirmation',
        },
        {
          name: 'created_at',
          title: this.$t('tables.headings.notificated_at'),
          callback: this.dateLabel,
          sortField: 'created_at',
        },
        {
          name: '__slot:actions',
          visible: this.tableData.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    filterData (filters) {
      if (!filters) {
        this.serverParams.queries = ''
        return
      }

      let queries = ''
      if (filters.region) {
        queries += '&region=' + filters.region
      }
      if (filters.country) {
        queries += '&country=' + filters.country
      }
      if (filters.district) {
        queries += '&district=' + filters.district
      }
      if (filters.team) {
        queries += '&team=' + filters.team
      }
      if (filters.yearSince) {
        queries += '&year_since=' + filters.yearSince
      }
      if (filters.yearUntil) {
        queries += '&year_until=' + filters.yearUntil
      }
      if (filters.name) {
        queries += '&title=' + filters.name
      }

      this.serverParams.queries = queries
    },
    totalFormat (total) {
      return '$' + parseFloat(total).toFixed(2)
    },
    confirmLabel (confirm) {
      let c = 'tables.status.unreviewed'
      switch (confirm) {
        case 1: c = 'layout.yes'
          break
        case -1: c = 'layout.no'
          break
      }

      return this.$t(c)
    },
    dateLabel (date) {
      return this.$date.format(date, 'MMMM - yyyy')
    },
    fixData (data) {
      // let noDef = this.$t('tables.undefined')
      for (const d of data) {
        if (!d.proyection) {
          d.proyection = {
            team: {
              code: '',
            },
          }
        }
      }
      return data
    },
    apiUrl (params) {
      let route = 'reports/investments'
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        let field = params.sort.field
        switch (field) {
          case 'team.code':
            field = 'Teams.code'
            break
          case 'district.country.name':
            field = 'Countries.name'
            break
          case 'district.country.region.name':
            field = 'Regions.name'
            break
        }
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }
      if (params.queries) {
        route += params.queries
      }

      return route
    },
    async updateData (params) {
      params = params || this.serverParams
      this.loading = true
      this.loadingError = false

      let response = false
      try {
        response = await this.$http.get(this.apiUrl(params))
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.loading = false
        this.loadingError = true
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async searchByName (name) {
      if (name === '') return this.updateData()
      this.loading = true

      let response = false
      try {
        response = await this.$http.get('proyection-investments?q=' + name)
      } catch (err) {
        // console.log('Error searching investment', err)
        this.loading = false
        return
      }

      this.tableData = this.fixData(response.data.data)
      this.pagination = response.data.pagination
      this.loading = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete('reports/investments/' + item.id)
      } catch (err) {
        // console.log('Error deleting investment', err)
        this.loading = false
        return
      }
      this.updateData()
    },
    async prepareDownload (type) {
      const prepareRoute = 'proyections?export=xlsx'
      const downloadRoute = 'proyectionss/download/'

      return this.downloadFile(this.currentQuery[type], prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
